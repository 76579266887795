import request from '@/utils/request'

// 查询通用配置管理列表
export function listConfig(query) {
  return request({
    url: '/common/config/list',
    method: 'get',
    params: query
  })
}

// 查询通用配置管理详细
export function getConfig(id) {
  return request({
    url: '/common/config/' + id,
    method: 'get'
  })
}

// 新增通用配置管理
export function addConfig(data) {
  return request({
    url: '/common/config',
    method: 'post',
    data: data
  })
}

// 修改通用配置管理
export function updateConfig(data) {
  return request({
    url: '/common/config',
    method: 'put',
    data: data
  })
}

// 删除通用配置管理
export function delConfig(id) {
  return request({
    url: '/common/config/' + id,
    method: 'delete'
  })
}
